const UNKNOWN_VALUE = 'UNKNOWN_VALUE';
const SEARCH_ERROR_HANDLER_DATADOG_KEY_PREFIX = 'search_error_handler';
const SEARCH_ERROR_HANDLER_DATADOG_KEY_CASES = {
  JS_ERROR: 'js_error',
  SCRIPT_CATCH: 'script_catch',
};

const TAG_APP_NEME = 'search';

export { UNKNOWN_VALUE, SEARCH_ERROR_HANDLER_DATADOG_KEY_PREFIX, SEARCH_ERROR_HANDLER_DATADOG_KEY_CASES, TAG_APP_NEME };
