export { ACTION_TYPES } from './components/actions';
export {
  UNKNOWN_VALUE,
  SEARCH_ERROR_HANDLER_DATADOG_KEY_PREFIX,
  SEARCH_ERROR_HANDLER_DATADOG_KEY_CASES,
  TAG_APP_NEME,
} from './components/error-constant';

export const FILTERS = {
  IDS: {
    FULFILLMENT: 'fulfillment',
    RELATED_SEARCHES: 'related_searches',
    COLOR: 'MAIN_COLOR',
    FILTRABLE_COLOR: 'FILTRABLE_COLOR',
    OFFICIAL_STORE: 'official_store',
    PRICE: 'price',
    VEHICLE_YEAR: 'VEHICLE_YEAR',
    VEHICLE_BODY_TYPE: 'VEHICLE_BODY_TYPE',
    STATE: 'state',
    CITY: 'city',
    NEIGHBORHOOD: 'neighborhood',
    LOCATION: 'LOCATION',
    ITEM_LOCATION: 'item_location',
    DISPLAY_TYPE: 'DisplayType',
    OPERATION: 'OPERATION',
    CATEGORY: 'category',
    PROPERTY_TYPE: 'PROPERTY_TYPE',
    OPERATION_SUBTYPE: 'OPERATION_SUBTYPE',
    PROJECTS: '245034',
  },
  TYPES: {
    RANGE: 'range',
    PRICE_WITH_CONVERSION: 'PRICE_WITH_CONVERSION',
    WITH_PICTURES: 'with_pictures',
    HIGHLIGHTED: 'highlighted',
    HIDDEN: 'hidden',
    GROUP: 'group',
  },
};
export const FILTERS_WEBVIEW_BS = {
  FONT_SIZES_NAMES: {
    SMALL: 'small',
    X_SMALL: 'x_small',
    XX_SMALL: 'xx_small',
    LARGE: 'large',
  },
  APPLIED: 'APPLIED',
  NOT_APPLIED: 'NOT_APPLIED',
  FONT_SIZES_VALUES: {
    XX_SMALL_VALUE: 10,
    X_SMALL_VALUE: 12,
    SMALL_VALUE: 14,
    LARGE_VALUE: 16,
  },
  ACTIONS: {
    START: 'START',
    CLEAR: 'CLEAR',
    SHOW: 'SHOW',
    OPEN_APPBAR: 'OPEN_APPBAR',
    UPDATE: 'UPDATE',
  },
  OPTIONS: {
    QUICKLY: 'QUICKLY',
    DEFAULT: 'DEFAULT',
  },
  METHOD: {
    FILTER_BS_DATA: 'FILTER_BS_DATA',
    FILTER_BS_CONNECT: 'FILTER_BS_CONNECT',
  },
  ASSETS: {
    ICON: 'icon',
    IMAGE: 'imageUrl',
    TEXT: 'text',
  },
};

export const SCROLL_BEHAVIOR = {
  INTANT: 'instant',
  SMOOTH: 'smooth',
};

export const DEVICE_TYPES = {
  MOBILE: 'mobile',
  DESKTOP: 'desktop',
  TABLET: 'tablet',
};

export const DEVICE_OS = {
  ANDROID: 'android',
  IOS: 'ios',
};

export const DISPLAY_TYPES = {
  MAP: 'M',
};

export const LAYOUTS = {
  TYPE_STACK: 'stack',
  TYPE_MOSAIC: 'mosaic',
  TYPE_GALLERY: 'gallery',
  TYPE_GRID: 'grid',
  TYPE_MAP: 'map',
};

export const VISUAL_IDS = {
  DEFAULT: 'default',
  PI: 'pi',
};
export const OFFICIAL_STORE_ALL = 'all';
export const OFFICIAL_STORE_KEYNAME = 'tienda';

export const PRELOAD_MOBILE = { stack: 2, gallery: 1, grid: 2 };
export const PRELOAD_DESKTOP = { stack: 2, grid: 3 };

export const SVG_TYPE = {
  IS_FILTER: 'IS_FILTER',
  DEFAULT: 'DEFAULT',
  IS_NEW_FILTER: 'IS_NEW_FILTER',
};

export const TYPE_RESULTS = {
  RESULT_ITEM: 'SEARCH_RESULT_ITEM',
  FILTER_INTERVENTION: 'FILTER_INTERVENTION',
  CONTENT_INTERVENTION: 'CONTENT_INTERVENTION',
  QUERY_INTERVENTION: 'QUERY_INTERVENTION',
  CAROUSEL_INTERVENTION: 'CAROUSEL_INTERVENTION',
  BILLBOARD_INTERVENTION: 'BILLBOARD_INTERVENTION',
  FACETED_SEARCH_INTERVENTION: 'FACETED_SEARCH_INTERVENTION',
  COMPATS_INTERVENTION: 'COMPATS_INTERVENTION',
  BRAND_ADS_INTERVENTION: 'BRAND_ADS_INTERVENTION',
  CART_INTERVENTION: 'CART_INTERVENTION',
  TREND_INTERVENTION: 'TREND_INTERVENTION',
  SEO_TEXTBOX_INTERVENTION: 'SEO_TEXTBOX_INTERVENTION',
  BRAND_DISCO_INTERVENTION: 'BRAND_DISCO_INTERVENTION',
  MELIPLAY_INTERVENTION: 'MELIPLAY_INTERVENTION',
  SEO_TOP_KEYWORDS_INTERVENTION: 'SEO_TOP_KEYWORDS_INTERVENTION',
  SEO_BLOGS_INTERVENTION: 'SEO_BLOGS_INTERVENTION',
  SEO_KEYWORD_SUMMARY_INTERVENTION: 'SEO_KEYWORD_SUMMARY_INTERVENTION',
  VEHICLE_BODY_SELECTOR_INTERVENTION: 'VEHICLE_BODY_SELECTOR_INTERVENTION',
  ZRP_DISCLAIMER_COMPONENT: 'ZRP_DISCLAIMER_COMPONENT',
  ANDES_MESSAGE_INTERVENTION: 'ANDES_MESSAGE_INTERVENTION',
  PHARMA_INTERVENTION: 'PHARMA_INTERVENTION',
  QUICK_ACCESS_INTERVENTION: 'QUICK_ACCESS_INTERVENTION',
  SEGMENT: 'SEGMENT',
  SEGMENT_TOP_KEYWORDS: 'SEGMENT_TOP_KEYWORDS',

  // types of category page and best sellers
  HIGHER_GROWTH_TREND: 'HIGHER_GROWTH',
  MOST_WANTED_TREND: 'MOST_WANTED',
  MOST_POPULAR_TREND: 'MOST_POPULAR',
  MANUAL_TRENDS: 'MANUAL_TRENDS',
  CONSOLIDATED_TRENDS: 'CONSOLIDATED_TRENDS',
  OTHER_FILLED_TRENDS: 'OTHER_FILLED_TRENDS',
  BEST_SELLERS_ITEMS: 'BEST_SELLER',
  RECOMMENDED_ITEMS: 'RECOMMENDED_ITEMS',
  RECOMMENDED_ITEMS_POLYCARDS: 'RECOMMENDED_ITEMS_POLYCARDS',
  SEO_BLOGS: 'SEO_BLOGS',
  BEST_SELLERS_POLYCARDS: 'BEST_SELLER_POLYCARDS',
};

export const ITEM_INTERVENTIONS = [TYPE_RESULTS.MELIPLAY_INTERVENTION];

export const INTERVENTIONS_IDS = [
  TYPE_RESULTS.FILTER_INTERVENTION,
  TYPE_RESULTS.QUERY_INTERVENTION,
  TYPE_RESULTS.CONTENT_INTERVENTION,
  TYPE_RESULTS.CAROUSEL_INTERVENTION,
  TYPE_RESULTS.BILLBOARD_INTERVENTION,
  TYPE_RESULTS.FACETED_SEARCH_INTERVENTION,
  TYPE_RESULTS.COMPATS_INTERVENTION,
  TYPE_RESULTS.BRAND_ADS_INTERVENTION,
  TYPE_RESULTS.TREND_INTERVENTION,
  TYPE_RESULTS.CART_INTERVENTION,
  TYPE_RESULTS.SEO_TEXTBOX_INTERVENTION,
  TYPE_RESULTS.HIGHER_GROWTH_TREND,
  TYPE_RESULTS.MOST_WANTED_TREND,
  TYPE_RESULTS.MOST_POPULAR_TREND,
  TYPE_RESULTS.MANUAL_TRENDS,
  TYPE_RESULTS.CONSOLIDATED_TRENDS,
  TYPE_RESULTS.OTHER_FILLED_TRENDS,
  TYPE_RESULTS.BEST_SELLERS_ITEMS,
  TYPE_RESULTS.RECOMMENDED_ITEMS,
  TYPE_RESULTS.RECOMMENDED_ITEMS_POLYCARDS,
  TYPE_RESULTS.BRAND_DISCO_INTERVENTION,
  TYPE_RESULTS.SEO_BLOGS,
  TYPE_RESULTS.MELIPLAY_INTERVENTION,
  TYPE_RESULTS.SEO_BLOGS_INTERVENTION,
  TYPE_RESULTS.SEO_TOP_KEYWORDS_INTERVENTION,
  TYPE_RESULTS.SEO_KEYWORD_SUMMARY_INTERVENTION,
  TYPE_RESULTS.VEHICLE_BODY_SELECTOR_INTERVENTION,
  TYPE_RESULTS.ZRP_DISCLAIMER_COMPONENT,
  TYPE_RESULTS.ANDES_MESSAGE_INTERVENTION,
  TYPE_RESULTS.BEST_SELLERS_POLYCARDS,
  TYPE_RESULTS.PHARMA_INTERVENTION,
  TYPE_RESULTS.QUICK_ACCESS_INTERVENTION,
];

export const FILTER_INTERVENTION = {
  TYPES: {
    FILTER_TEXT: 'FILTER_TEXT',
    FILTER_SPECIALIZED: 'FILTER_SPECIALIZED',
    FILTER_COLOR: 'FILTER_COLOR',
  },
};

export const TREND_INTERVENTION = {
  TYPES: {
    TREND_UNIT: 'TREND_UNIT',
    TREND_CENTER: 'TREND_CENTER',
    TREND_CAROUSEL: 'TREND_CARPUISEL',
  },
};

export const BRAND_DISCO_INTERVENTION = {
  TYPES: {
    BRAND_DISCO_INTERVENTION: 'BRAND_DISCO_INTERVENTION',
  },
};

export const LAZY_LOADING = {
  OFF: 'off',
  ON: 'on',
};

export const VISIBLE = 'VISIBLE';

export const MIN_INPUT_NAME = 'Minimum';
export const SINCE_ITEM_NAME = 'From';
export const UNTIL_ITEM_NAME = 'To';
export const MAX_INPUT_NAME = 'Maximum';
export const ERROR = 'error';

export const TYPE_RANGE = {
  DROPDOWN: 'dropdown',
  INPUT: 'input',
};

export const MAPPED_FACETED_FILTER_IDS = {
  only_new: 'OPERATION_SUBTYPE',
  operation: 'OPERATION',
  property: 'PROPERTY_TYPE',
};

export const ANDES_MESSAGE_CONFIG = {
  andes_message: {
    is_dismissable: true,
    hierarchy: 'loud',
  },
};

export const UI_SEARCH_ZRP_DISCLAIMER_NAMESPACE = 'ui-search-zrp-disclaimer';

export const UI_SEARCH_FILTER_COLORS = 'ui-search-filter-colors';

export const UI_CATEGORY_TRENDS_EXPANDABLE = 'ui-category-trends-expandable';

export const DEFAULT_LOCATION_FILTER_KEY = 'ui-search-filters__location-filter';

export const UI_SEARCH_RELATED_SEARCHES_NAMESPACE = 'ui-search-related-searches';

export const FILTER_GROUP_SEPARATOR = ':';

export const CURRENCIES_REGEX = /(\$|Bs|R\$|UF|₡|CUC|€|Q|L|C\$|B\/\.|S\/|₲|US\$|U\$S|Bs\.)/g;
